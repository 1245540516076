@import "/node_modules/bootstrap/scss/bootstrap.scss";

main {
  padding-top: 80px;
}

#mainAlert,
#changesetAlert {
  display: none;
}

#editorInterface {
  display: none;
}

#searchInterface {
  display: none;
}

#lang-select {
  display: none;
}

.detail-img {
  width: 20%;
}
